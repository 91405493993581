import MainNavigation from "components/MainNavigation";
import Footer from "components/Footer";
import { Outlet, useLocation, ScrollRestoration } from "react-router-dom";
import { useLayoutEffect } from "react";

const RootLayout = () => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <ScrollRestoration />
      <MainNavigation />
      <Outlet />
      <Footer />
    </>
  );
};

export default RootLayout;
