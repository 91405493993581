import ContentContainer from "components/UI/ContentContainer";
import FeatureItem from "./FeatureItem";
import styles from "./index.module.scss";
import CryptoReports from "assets/icons/features/crypto-reports.svg";
import SentimentalIndicators from "assets/icons/features/sentimental-indicators.svg";
import TechnicalSignals from "assets/icons/features/technical-signals.svg";
import TradingBots from "assets/icons/features/trading-bots.svg";

const features = [
  {
    icon: SentimentalIndicators,
    title: "Sentiment Indicators",
    description: [
      "Leverage real-time sentiment indicators to gauge market mood",
      "Sentiments are aggregated from Google, YouTube, and other reliable channels.",
      "Perfect for traders looking to capitalize on market momentum.",
    ],
  },
  {
    icon: TechnicalSignals,
    title: "Technical Signals",
    description: [
      "Get access to precise technical trading signals based on rigorous analysis.",
      "Includes Moving Averages, Bollinger Bands, RSI, and more.",
      "Ideal for traders seeking entry and exit points based on technical analysis.",
    ],
  },
  {
    icon: TradingBots,
    title: "AI-Driven Trading Bots",
    description: [
      "Utilize AI-driven bots for automated, 24/7 trading.",
      "Includes risk management, stop-loss, and take-profit features.",
      "Perfect for traders who want to capitalize on market opportunities around the clock.",
    ],
  },
  {
    icon: CryptoReports,
    title: "Crypto reports",
    description: [
      "Weekly and monthly reports for in-depth market analysis.",
      "Includes sentiment trends, technical indicators, and performance metrics.",
      "Excellent for traders and investors seeking a comprehensive market snapshot.",
    ],
  },
];

const Features = () => {
  return (
    <div className={styles["section-features"]}>
      <ContentContainer className={styles.container}>
        <article className={styles["intro-container"]}>
          <div className={styles["text-container"]}>
            <h3 className={styles.title}>Features that empower your trading</h3>
            <p>
              Our platform integrates a rich set of tools and analytics designed
              for the unique challenges and opportunities presented by all
              markets. Whether a day trader or a long-term investor, our
              features will help you make more informed decisions.
            </p>
          </div>
          <div className={styles["image-container"]}></div>
        </article>
        <div className={styles["feature-items"]}>
          {features.map((item) => (
            <FeatureItem
              key={item.title}
              icon={item.icon}
              title={item.title}
              description={item.description}
            />
          ))}
        </div>
      </ContentContainer>
    </div>
  );
};

export default Features;
