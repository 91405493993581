import Hero from "components/UI/sections/Hero";
import About from "components/UI/sections/About";
import Features from "components/UI/sections/Features";
import Team from "components/UI/sections/Team";
import News from "components/UI/sections/News";

const HomePage = () => {
  return (
    <>
      <Hero />
      <About />
      <Features />
      <Team />
      <News />
    </>
  );
};

export default HomePage;
