import { useEffect, useState } from "react";
import ContentContainer from "components/UI/ContentContainer";
import styles from "./index.module.scss";

const Hero = () => {
  const [pageIsLoaded, setPageIsLoaded] = useState(false);
  useEffect(() => {
    const onPageLoad = () => {
      setPageIsLoaded(true);
    };

    if (document.readyState === "complete") {
      onPageLoad();
      setPageIsLoaded(true);
    } else {
      window.addEventListener("load", onPageLoad, false);
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <div
      className={pageIsLoaded ? `${styles["hero-loaded"]}` : `${styles.hero}`}>
      <ContentContainer
        className={
          pageIsLoaded
            ? styles["hero-container-loaded"]
            : styles["hero-container"]
        }>
        <h1 className={styles["hero-title"]}>
          <span className={styles["blue-text"]}>
            Transforming Financial Data
          </span>{" "}
          into Strategic Business Insights with FinPredict
        </h1>
        <span className={styles["hero-subtitle"]}>
          Leverage predictive analytics to empower your enterprise
          decision-making
        </span>
      </ContentContainer>
    </div>
  );
};

export default Hero;
