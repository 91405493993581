import styles from "./index.module.scss";

type Props = {
  children: string | JSX.Element | JSX.Element[];
  className?: string;
};

const ContentContainer: React.FC<Props> = ({ children, className }) => (
  <div
    className={`${styles["section-container"]} ${className ? className : ""}`}>
    {children}
  </div>
);

export default ContentContainer;
