import styles from "./index.module.scss";

type Props = {
  title: string;
};

const PageTitle: React.FC<Props> = ({ title }) => (
  <div className={styles["page-title-container"]}>
    <h2 className={styles["page-title"]}>{title}</h2>
  </div>
);

export default PageTitle;
