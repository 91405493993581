import styles from "./index.module.scss";
import TimelineComponent from "./Timeline";
import { TimelineItems } from "./Timeline";

type Props = {
  className?: string;
};

const Timeline: React.FC<Props> = ({ className }) => {
  const items: TimelineItems[] = [
    {
      title: "2019",
      cardTitle: "The Genesis",
      cardDetailedText:
        "In 2019, TwitterWatcher (now FinPredict) was born out of the innovative vision of our founder, Lukasz Wedel. The original aim was to explore the untapped potential of social media sentiment in influencing cryptocurrency prices.",
    },
    {
      title: "2020",
      cardTitle: "A Turning Point",
      cardDetailedText:
        "Our pivotal year was 2020, when we welcomed Piotr to our team. An experienced trader, Piotr revolutionized our understanding of what traders truly need. He helped us fine-tune our algorithms and expand beyond sentiment analysis to offer a full suite of trading signals.",
    },
    {
      title: "2021",
      cardTitle: "The First Milestone",
      cardDetailedText:
        "Our hard work bore fruit in 2021 when we secured our first customer, LiteFinance. This was a momentous occasion for us, validating our approach and opening doors to new opportunities.",
    },
    {
      title: "2022",
      cardTitle: "The Year of Refinement",
      cardDetailedText:
        "In 2022, we focused on optimizing our models, improving our signals, and enhancing our customer experience. It was a year of iterative improvement and setting the stage for what was to come.",
    },
    {
      title: "2023",
      cardTitle: "A New Era",
      cardDetailedText:
        "Fast forward to 2023, and we've entered a new chapter. With a name change (FinPredict) and a more focused mission, we're expanding our offering to be more than just a sentiment analysis tool. Utilizing cutting-edge cloud technology, we are evolving into an all-encompassing financial prediction platform.",
    },
  ];

  return (
    <div className={`${styles.timeline} ${className ? className : ""}`}>
      <div className={styles["timeline-mode-vertical"]}>
        <TimelineComponent items={items} />
      </div>
      <div className={styles["timeline-mode-horizontal"]}>
        <TimelineComponent
          items={items}
          mode="HORIZONTAL"
          hideControls={false}
          lineWidth={10}
        />
      </div>
    </div>
  );
};
export default Timeline;
