import styles from "./index.module.scss";

type Props = {
  image?: string;
  linkedinProfile?: string;
  name: string;
  position: string;
  description: string;
};

const TeamItem: React.FC<Props> = ({
  image,
  linkedinProfile,
  name,
  position,
  description,
}) => {
  return (
    <article className={styles["team-item"]}>
      <a
        href={linkedinProfile}
        target="_blank"
        rel="noopener"
        className={styles["team-item-img-container"]}>
        <div
          className={styles["team-item-img"]}
          style={{ backgroundImage: `url(${image})` }}></div>
        {linkedinProfile && <span className={styles["linkedin-icon"]}></span>}
      </a>
      <h4 className={styles["team-item-name"]}>{name}</h4>
      <p className={styles["team-item-position"]}>{position}</p>
      <p className={styles["team-item-description"]}>{description}</p>
    </article>
  );
};

export default TeamItem;
