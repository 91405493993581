import styles from "./index.module.scss";

type Props = {
  children: string;
  url: string;
  className?: string;
  externalLink?: boolean;
};

const Link: React.FC<Props> = ({ children, url, className, externalLink }) => (
  <a
    href={url}
    className={`${styles.link} ${className}`}
    target={externalLink ? "_blank" : undefined}
    rel={externalLink ? "noopener" : undefined}>
    {children}
  </a>
);

export default Link;
