import ContentContainer from "components/UI/ContentContainer";
import PageTitle from "components/UI/PageTitle";
import Link from "components/UI/Link";
import styles from "./index.module.scss";

const ContactPage = () => {
  return (
    <div className={styles["page"]}>
      <ContentContainer className={styles.container}>
        <PageTitle title="Contact" />
        <p>
          Welcome to FinPredict, your gateway to smarter, more effective trading
          strategies in the cryptocurrency and broader financial markets. As a
          technology-driven company, we specialize in advanced data analytics
          and algorithmic trading. With a suite of tailor-made tools and
          services, we help individual traders, financial institutions, and
          businesses gain a competitive edge in today's dynamic trading
          environment.
        </p>
        <p>
          We are here to assist you on your trading journey and we invite you to
          reach out with any questions, concerns or feedback you may have.
        </p>

        <p>
          Contact us at&nbsp;
          <Link url="mailto:contact@finpredict.com">
            contact@finpredict.com
          </Link>
          &nbsp; to learn more.
        </p>
      </ContentContainer>
    </div>
  );
};

export default ContactPage;
