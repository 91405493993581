import ContentContainer from "components/UI/ContentContainer";
import TeamItem from "./TeamItem";
import styles from "./index.module.scss";
import Łukasz from "assets/images/team/lukasz.png";
import Ivan from "assets/images/team/ivan.png";
import Marcin from "assets/images/team/marcin.png";
import Adam from "assets/images/team/adam.png";
import Justyna from "assets/images/team/justyna.png";
import Piotr from "assets/images/team/piotr.png";
import Avatar from "assets/icons/avatar.svg";

const teamMembers = [
  {
    image: Łukasz,
    linkedinProfile: "https://www.linkedin.com/in/lukaszwedel/",
    name: "Lukasz Wędel",
    position: "CEO",
    description:
      "Lukasz brings over 18+ years of cross-sector experience to FinPredict as a developer, manager and founder. His multifaceted expertise makes him a target-driven leader capable of steering the company to new heights.",
  },
  {
    image: Ivan,
    linkedinProfile: "https://www.linkedin.com/in/markeyev/",
    name: "Ivan Markeev",
    position: "CTO",
    description:
      "Ivan is an ex-Googler with an impressive 20-year background in IT. As our certified Professional Cloud Architect, he specializes in data gathering, analysis, and bot management. His leadership and vision are integral to our technological advancement.",
  },
  {
    image: Marcin,
    linkedinProfile: "https://www.linkedin.com/in/marcinmucha/",
    name: "Marcin Mucha",
    position: "COO",
    description:
      "With over eight years of managerial experience, Marcin excels in operational efficiency. He’s pulling all the strings to ensure everything runs like clockwork, capitalizing on his vast network of contacts.",
  },
  {
    image: Piotr,
    name: "Piotr Wilczyński",
    position: "Head of Trading Algorithms",
    description:
      "Piotr's trading acumen is our compass, guiding us to develop tools and algorithms that traders genuinely need.",
  },
  {
    image: Justyna,
    linkedinProfile: "https://www.linkedin.com/in/justyna-wedel/",
    name: "Justyna Wędel",
    position: "Frontend Developer",
    description:
      "Since launching her career in 2017, Justyna has honed her skills in delivering visually compelling and user-friendly designs. She translates our data into visual insights you can immediately act upon.",
  },
  {
    image: Adam,
    linkedinProfile: "https://www.linkedin.com/in/adam-mucha-33bb0266/",
    name: "Adam Mucha",
    position: "Backend Developer",
    description:
      "Adam, an ex-ABB employee with 18 years in IT, is our technical wizard. Whether it's implementing technical analysis, optimization or troubleshooting, Adam is the brain behind the operation.",
  },
  {
    image: Avatar,
    name: "Juan",
    position: "Bot Developer/Data",
    description:
      "Juan’s almost twenty years in software development have been defined by his versatility and adaptability. Specializing in data analysis and system infrastructure, he thrives in fast-paced, interdisciplinary environments. His focus and pragmatism are invaluable assets.",
  },
];
const Team = () => {
  return (
    <div className={styles["section-team"]}>
      <ContentContainer className={styles.container}>
        <article className={styles["section-content"]}>
          <h3 className={styles.heading}>Our Team</h3>
          <p>
            Meet the Strategic Minds Behind FinPredict: Transforming Business
            Through Data-Driven Insights
          </p>
          <p>
            We believe in the power of interdisciplinary knowledge to solve
            complex challenges. Our team is a blend of financial experts, data
            scientists, traders, and tech innovators, all working together to
            make financial markets more predictable for you.
          </p>
        </article>
        <div className={styles["team-items"]}>
          {teamMembers.map((item) => (
            <TeamItem
              key={item.name}
              image={item.image}
              linkedinProfile={item.linkedinProfile}
              name={item.name}
              position={item.position}
              description={item.description}
            />
          ))}
        </div>
      </ContentContainer>
    </div>
  );
};

export default Team;
