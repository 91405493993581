import Link from "components/UI/Link";
import ContentContainer from "components/UI/ContentContainer";
import styles from "./index.module.scss";
import pdfFile from "assets/docs/FinPredict_Terms_and_Conditions.pdf";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <ContentContainer>
        <ul className={styles["footer-list"]}>
          <li className={styles["footer-list-item"]}>
            <a
              href="https://twitter.com/hashtag_watcher"
              className={`${styles["footer-link-icon"]} ${styles.twitter}`}
              target="_blank"
              rel="noopener"></a>
          </li>
          <li className={styles["footer-list-item"]}>
            <Link url={pdfFile} className={styles["footer-link"]} externalLink>
              Terms & Conditions
            </Link>
          </li>
        </ul>

        <p className={styles.copyright}>
          © 2023 FinPredict. All Rights Reserved.
        </p>
      </ContentContainer>
    </footer>
  );
};

export default Footer;
