import { Chrono } from "react-chrono";
import styles from "./index.module.scss";

type Props = {
  className?: string;
  items: TimelineItems[];
  mode?: "VERTICAL" | "HORIZONTAL";
  lineWidth?: number;
  hideControls?: boolean;
};

export type TimelineItems = {
  title: string;
  cardTitle: string;
  cardDetailedText: string;
};

const TimelineComponent: React.FC<Props> = ({
  className,
  items,
  mode = "VERTICAL",
  lineWidth = 5,
  hideControls = true,
}) => {
  return (
    <div className={`${styles.timeline} ${className ? className : ""}`}>
      <Chrono
        items={items}
        mode={mode}
        hideControls={hideControls}
        useReadMore={false}
        lineWidth={lineWidth}
        activeItemIndex={4}
        theme={{
          primary: "#002f3d",
        }}
        scrollable={true}
        fontSizes={{
          cardSubtitle: "0.85rem",
          cardText: "0.8rem",
          cardTitle: "1rem",
          title: "1.5rem",
        }}
        classNames={{
          cardText: styles["card-text"],
          controls: styles["timeline-controls"],
          timelineTitleWrapper: styles.wrapper,
        }}
      />
    </div>
  );
};
export default TimelineComponent;
