import { useState, useEffect } from "react";
import logo from "assets/icons/logo-finPredict.svg";
import menuIcon from "assets/icons/menu.svg";
import ContentContainer from "components/UI/ContentContainer";
import styles from "./index.module.scss";
import { NavLink } from "react-router-dom";

const MainNavigation = () => {
  const [isNavExpanded, setNavExpanded] = useState(false);

  const toggleMobileMenu = () => {
    setNavExpanded(!isNavExpanded);
  };
  const handleClickNavLink = () => setNavExpanded(false);

  useEffect(() => {
    window.addEventListener("resize", () => setNavExpanded(false));
  });

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = isNavExpanded ? "hidden" : "";
    }
  }, [isNavExpanded]);

  return (
    <header className={styles.header}>
      <ContentContainer>
        <div className={styles.container}>
          <NavLink
            to="/"
            className={styles["logo-container"]}
            onClick={handleClickNavLink}>
            <img src={logo} className={styles.logo} alt="FinPredict logo" />
          </NavLink>
          <button className={styles.menuButton} onClick={toggleMobileMenu}>
            <img src={menuIcon} alt="Menu icon" className={styles.menuIcon} />
          </button>
          <nav
            className={`${styles["navigation-menu"]} ${
              isNavExpanded && styles.expanded
            }`}>
            <NavLink
              to="/"
              className={styles["navigation-link"]}
              onClick={handleClickNavLink}>
              About the platform
            </NavLink>
            <NavLink
              to="/contact"
              className={styles["navigation-link"]}
              onClick={handleClickNavLink}>
              Contact
            </NavLink>
          </nav>
        </div>
      </ContentContainer>
    </header>
  );
};

export default MainNavigation;
