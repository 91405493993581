import ContentContainer from "components/UI/ContentContainer";
import Timeline from "components/Timeline/";
import PageTitle from "components/UI/PageTitle";
import styles from "./index.module.scss";

const About = () => {
  return (
    <div className={styles["section-about"]}>
      <ContentContainer className={styles.container}>
        <PageTitle title="About us" />
        <h4 className={styles.subheading}>
          Our Journey to Business-centric Predictive Excellence
        </h4>
        <p>
          Our mission is transforming cryptocurrency trends with advanced
          multi-platform sentiment analysis for optimized trading and investment
          strategies.
        </p>
        <p>
          This mission statement underscores the transformative power of
          CloudDataWatcher in shaping cryptocurrency trends. It also highlights
          the core function of the company: providing advanced sentiment
          analysis across multiple platforms. This helps optimize trading and
          investment strategies, ultimately leading to better financial results
          for users. The use of terms like "transforming," "advanced,"
          "multi-platform," and "optimized" evokes a sense of cutting-edge
          innovation and efficiency, which could be appealing to the tech-savvy
          target audience in the crypto market.
        </p>
        <Timeline />
      </ContentContainer>
    </div>
  );
};

export default About;
