import styles from "./index.module.scss";

type Props = {
  icon: string;
  title: string;
  description: string[];
};

const FeatureItem: React.FC<Props> = ({ icon, title, description }) => {
  return (
    <article className={styles["feature-block"]}>
      <img src={icon} alt={title} className={styles["feature-image"]} />
      <h3 className={styles["feature-title"]}>{title}</h3>
      <ul>
        {description.map((listItem) => (
          <li key={listItem} className={styles["feature-list-item"]}>
            {listItem}
          </li>
        ))}
      </ul>
    </article>
  );
};

export default FeatureItem;
