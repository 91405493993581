import ContentContainer from "components/UI/ContentContainer";
import styles from "./index.module.scss";

const News = () => {
  return (
    <div className={styles["section-news"]}>
      <ContentContainer className={styles.container}>
        <div className={styles["image-container"]}></div>
        <div className={styles["text-container"]}>
          <h3 className={styles.heading}>News & Updates</h3>
          <p>
            Unlock new dimensions in corporate financial analysis and risk
            management. Something groundbreaking is on the horizon!
          </p>
        </div>
      </ContentContainer>
    </div>
  );
};

export default News;
